footer {
  background-color: #2c2c33;
  margin-top: 20px;
  padding: 20px;
}

footer > div.logo-text-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

footer > img {
  width: 40px;
  height: 40px;
}

div.social-logos {
  display: flex;
}

div.social-logos > a {
  margin: 10px;
}

span#copyright-text {
  color: rgba(255, 255, 255, 0.685);
}
