div.progress {
  background-color: black;
  width: 100%;
  height: 20px;
  border-radius: 50px;
}

div.progress-bar {
  background-color: white;
  height: 20px;
  border-radius: 50px;
}
