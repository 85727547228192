div.tool-case {
  display: flex;
  border-radius: 20px;
  transition: ease-in-out 0.2s;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  width: 70vw;
  margin: 10px;
}

div.tool-case:hover {
  transform: scale(1.06);
  transition: ease-in-out 0.2s;
}

div.tool-case > img {
  width: 5%;
  height: 5%;
}

div.tool-case > h3 {
  font-weight: normal;
}

div.tool-case > * {
  margin: 5px;
}
