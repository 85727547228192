button.slide-button {
  border: 2px solid currentColor;
  border-radius: 3rem;
  width: 10rem;
  background-color: transparent;
  color: white;
  font-weight: 100;
  overflow: hidden;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
}

button.slide-button::after {
  background-color: white;
  border-radius: 3rem;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-out;
  will-change: transform;
  z-index: -1;
}

button.slide-button:hover::after {
  transform: translate(0, 0);
}

button.slide-button:hover {
  border: 2px solid transparent;
  color: black;
  font-weight: bold;
  transform: scale(1.05);
  will-change: transform;
}

@media (max-width: 648px) {
  button.slide-button {
    font-size: 3vw;
    width: 25vw;
  }
}

@media (max-width: 480px) {
  button.slide-button {
    font-size: 2.5vw;
  }
}
