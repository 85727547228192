div.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 1196px) {
  div.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 774px) {
    div.grid {
      display: grid;
      width: 0px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
