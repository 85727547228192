div.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.726);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

div#popup-card {
  width: 50%;
  height: 50%;
  position: relative;
  border-radius: 20px;
}

div#popup-card > div.popup-content {
  padding: 20px;
}
