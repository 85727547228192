@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
/* Nav Styles */
nav#home-page-nav,
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a#Nav-header {
  margin: 25px;
  font-size: 2.5rem;
}

ul.nav-links {
  list-style-type: none;
  display: flex;
  margin: 25px;
  width: 50%;
  align-items: center;
  justify-content: space-around;
}

a {
  color: white;
  text-decoration: none;
}

/* Main */
/* Hero Section */
section.Hero-Section {
  width: 100vw;
  display: grid;
  grid-template-columns: 60% 40%;
}

section.Hero-Section > div.left-side-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 25px;
}

section.Hero-Section > div.left-side-content > h1 {
  font-weight: bold;
  word-spacing: 0.313rem;
  font-size: 3vw;
}

section.Hero-Section > div.right-side-content {
  padding: 20px;
}

section.Hero-Section > div.right-side-content > img {
  width: 100%;
  height: 100%;
  margin-right: 10px;
}

/* Showcase sections */
section.showcase-sections {
  margin: 0px 25px;
}

span.bar {
  background-color: white;
  width: 30px;
  height: 3px;
  margin: 2.5px;
  border-radius: 10px;
}

div#hamburger {
  display: none;
}

a.popup-nav-link {
  padding: 10px;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  width: 90%;
  text-align: center;
}

a.popup-nav-link:hover {
  background-color: #f8f8ff;
  color: black;
  font-weight: bolder;
  transition: 0.2s ease-in-out;
}

@media (max-width: 648px) {
  li#contact-link {
    display: none;
  }

  ul#home-page-links {
    display: none;
  }

  div#hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    cursor: pointer;
    align-items: center;
  }

  a#Nav-header {
    font-size: 2rem;
  }

  section.Hero-Section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  section.Hero-Section > div.right-side-content > img {
    width: 300px;
    height: 300px;
  }

  section.Hero-Section > div.left-side-content > h1 {
    font-size: 4.5vw;
  }

  section.showcase-sections > h2 {
    font-size: 20px;
  }

  section#contact {
    display: none;
  }

  section.tool-section > div.tool-case > img {
    width: 8vw;
    height: 6vh;
  }

  section.tool-section > div.tool-case > div.progress {
    height: 10px;
  }

  section.tool-section > div.tool-case > div.progress > div.progress-bar {
    height: 10px;
  }

  section.tool-section > div.tool-case {
    height: 50px;
  }

  section.tool-section {
    width: 0px;
  }
}

button.slide-button {
  border: 2px solid currentColor;
  border-radius: 3rem;
  width: 10rem;
  background-color: transparent;
  color: white;
  font-weight: 100;
  overflow: hidden;
  transition: 0.2s -webkit-transform ease-in-out;
  transition: 0.2s transform ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
  will-change: transform;
  z-index: 0;
}

button.slide-button::after {
  background-color: white;
  border-radius: 3rem;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-100%, 0) rotate(10deg);
          transform: translate(-100%, 0) rotate(10deg);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  will-change: transform;
  z-index: -1;
}

button.slide-button:hover::after {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

button.slide-button:hover {
  border: 2px solid transparent;
  color: black;
  font-weight: bold;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  will-change: transform;
}

@media (max-width: 648px) {
  button.slide-button {
    font-size: 3vw;
    width: 25vw;
  }
}

@media (max-width: 480px) {
  button.slide-button {
    font-size: 2.5vw;
  }
}

button {
  padding: 10px;
  border: none;
  color: white;
  font-family: "Comfortaa", cursive;
  font-size: 1.2rem;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
}

div.progress {
  background-color: black;
  width: 100%;
  height: 20px;
  border-radius: 50px;
}

div.progress-bar {
  background-color: white;
  height: 20px;
  border-radius: 50px;
}

div.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.726);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

div#popup-card {
  width: 50%;
  height: 50%;
  position: relative;
  border-radius: 20px;
}

div#popup-card > div.popup-content {
  padding: 20px;
}

input {
  padding: 10px 20px;
  background-color: #555564;
  border-radius: 100px;
  transition: 0.4s;
  color: white;
  border-style: none;
  margin: 5px;
  width: 100%;
  outline: none;
  font-family: "Comfortaa", cursive;
}

input:focus {
  outline: none;
  transition: 0.4s;
  box-shadow: 0px 4px 20px 2px #9ec2d3;
}

input::-webkit-input-placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

input::placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

input:-ms-input-placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

input::-ms-input-placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

textarea {
  padding: 10px 10px;
  background-color: #555564;
  border-radius: 10px;
  transition: 0.4s;
  color: white;
  border-style: none;
  margin: 5px;
  width: 100%;
  outline: none;
  font-family: "Comfortaa", cursive;
}

textarea::-webkit-input-placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

textarea::placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

textarea:-ms-input-placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

textarea::-ms-input-placeholder {
  font-family: "Comfortaa", cursive;
  color: rgba(246, 246, 246, 0.77);
}

input.file-upload {
  background-color: transparent;
  color: white;
  border-style: none;
  margin: 5px;
  outline: none;
  font-family: "Comfortaa", cursive;
}

div#tool-content {
  padding: 20px;
}

div.tool-case {
  display: flex;
  border-radius: 20px;
  transition: ease-in-out 0.2s;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  width: 70vw;
  margin: 10px;
}

div.tool-case:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: ease-in-out 0.2s;
}

div.tool-case > img {
  width: 5%;
  height: 5%;
}

div.tool-case > h3 {
  font-weight: normal;
}

div.tool-case > * {
  margin: 5px;
}

div.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 1196px) {
  div.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 774px) {
    div.grid {
      display: grid;
      width: 0px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

div.skill-card {
  padding: 10px;
  display: flex;
  width: 300px;
  height: 110px;
  transition: ease-in-out 0.2s;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

section#skill-section {
  display: flex;
  align-items: center;
  margin: 15px 0px;
  justify-content: center;
  width: 90vw;
}

div.skill-card:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: ease-in-out 0.2s;
}

@media (max-width: 648px) {
  section#skill-section {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: center;
    width: 25vw;
  }
}

@media (max-width: 439px) {
  section#skill-section {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: center;
    width: 15vw;
  }
}

@media (max-width: 392px) {
  section#skill-section {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: center;
    width: 5vw;
  }
}

@media (max-width: 375px) {
  section#skill-section {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: center;
    width: 0vw;
  }
}

@media (max-width: 346px) {
  section#skill-section {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: center;
    width: 10vw;
  }

  section#skill-section > div.grid > div.skill-card {
    padding: 10px;
    display: flex;
    width: 250px;
    height: 100px;
    transition: ease-in-out 0.2s;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
}

div.contact-card {
  width: 40vw;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
}

form#contact-form {
  display: block;
}

form#contact-form > * {
  margin: 10px 0px;
}

form#contact-form > textarea {
  width: 36vw;
}

footer {
  background-color: #2c2c33;
  margin-top: 20px;
  padding: 20px;
}

footer > div.logo-text-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

footer > img {
  width: 40px;
  height: 40px;
}

div.social-logos {
  display: flex;
}

div.social-logos > a {
  margin: 10px;
}

span#copyright-text {
  color: rgba(255, 255, 255, 0.685);
}

.ABAP {
  background-color: #e8274b;
}
.ActionScript {
  background-color: #882b0f;
}
.Ada {
  background-color: #02f88c;
}
.Agda {
  background-color: #315665;
}
.AGS-Script {
  background-color: #b9d9ff;
}
.Alloy {
  background-color: #64c800;
}
.AMPL {
  background-color: #e6efbb;
}
.ANTLR {
  background-color: #9dc3ff;
}
.API-Blueprint {
  background-color: #2acca8;
}
.APL {
  background-color: #5a8164;
}
.Arc {
  background-color: #aa2afe;
}
.Arduino {
  background-color: #bd79d1;
}
.ASP {
  background-color: #6a40fd;
}
.AspectJ {
  background-color: #a957b0;
}
.Assembly {
  background-color: #6e4c13;
}
.ATS {
  background-color: #1ac620;
}
.AutoHotkey {
  background-color: #6594b9;
}
.AutoIt {
  background-color: #1c3552;
}
.BlitzMax {
  background-color: #cd6400;
}
.Boo {
  background-color: #d4bec1;
}
.Brainfuck {
  background-color: #2f2530;
}
.C-Sharp {
  background-color: #178600;
}
.C {
  background-color: #555555;
}
.Chapel {
  background-color: #8dc63f;
}
.Cirru {
  background-color: #ccccff;
}
.Clarion {
  background-color: #db901e;
}
.Clean {
  background-color: #3f85af;
}
.Click {
  background-color: #e4e6f3;
}
.Clojure {
  background-color: #db5855;
}
.CoffeeScript {
  background-color: #244776;
}
.ColdFusion-CFC {
  background-color: #ed2cd6;
}
.ColdFusion {
  background-color: #ed2cd6;
}
.Common-Lisp {
  background-color: #3fb68b;
}
.Component-Pascal {
  background-color: #b0ce4e;
}
.cpp {
  background-color: #f34b7d;
}
.Crystal {
  background-color: #776791;
}
.CSS {
  background-color: #563d7c;
}
.D {
  background-color: #ba595e;
}
.Dart {
  background-color: #00b4ab;
}
.Diff {
  background-color: #88dddd;
}
.DM {
  background-color: #447265;
}
.Dogescript {
  background-color: #cca760;
}
.Dylan {
  background-color: #6c616e;
}
.E {
  background-color: #ccce35;
}
.Eagle {
  background-color: #814c05;
}
.eC {
  background-color: #913960;
}
.ECL {
  background-color: #8a1267;
}
.edn {
  background-color: #db5855;
}
.Eiffel {
  background-color: #946d57;
}
.Elixir {
  background-color: #6e4a7e;
}
.Elm {
  background-color: #60b5cc;
}
.Emacs-Lisp {
  background-color: #c065db;
}
.EmberScript {
  background-color: #fff4f3;
}
.Erlang {
  background-color: #b83998;
}
.F-Sharp {
  background-color: #b845fc;
}
.Factor {
  background-color: #636746;
}
.Fancy {
  background-color: #7b9db4;
}
.Fantom {
  background-color: #dbded5;
}
.FLUX {
  background-color: #88ccff;
}
.Forth {
  background-color: #341708;
}
.FORTRAN {
  background-color: #4d41b1;
}
.FreeMarker {
  background-color: #0050b2;
}
.Frege {
  background-color: #00cafe;
}
.Game-Maker-Language {
  background-color: #8fb200;
}
.Glyph {
  background-color: #e4cc98;
}
.Gnuplot {
  background-color: #f0a9f0;
}
.Go {
  background-color: #375eab;
}
.Golo {
  background-color: #88562a;
}
.Gosu {
  background-color: #82937f;
}
.Grammatical-Framework {
  background-color: #79aa7a;
}
.Groovy {
  background-color: #e69f56;
}
.Handlebars {
  background-color: #01a9d6;
}
.Harbour {
  background-color: #0e60e3;
}
.Haskell {
  background-color: #29b544;
}
.Haxe {
  background-color: #df7900;
}
.HTML {
  background-color: #e44b23;
}
.Hy {
  background-color: #7790b2;
}
.IDL {
  background-color: #a3522f;
}
.Io {
  background-color: #a9188d;
}
.Ioke {
  background-color: #078193;
}
.Isabelle {
  background-color: #fefe00;
}
.J {
  background-color: #9eedff;
}
.Java {
  background-color: #b07219;
}
.JavaScript {
  background-color: #f1e05a;
}
.JFlex {
  background-color: #dbca00;
}
.JSONiq {
  background-color: #40d47e;
}
.Julia {
  background-color: #a270ba;
}
.Jupyter-Notebook {
  background-color: #da5b0b;
}
.Kotlin {
  background-color: #f18e33;
}
.KRL {
  background-color: #28431f;
}
.Lasso {
  background-color: #999999;
}
.Latte {
  background-color: #a8ff97;
}
.Lex {
  background-color: #dbca00;
}
.LFE {
  background-color: #004200;
}
.LiveScript {
  background-color: #499886;
}
.LOLCODE {
  background-color: #cc9900;
}
.LookML {
  background-color: #652b81;
}
.LSL {
  background-color: #3d9970;
}
.Lua {
  background-color: #000080;
}
.Makefile {
  background-color: #427819;
}
.Mask {
  background-color: #f97732;
}
.Matlab {
  background-color: #bb92ac;
}
.Max {
  background-color: #c4a79c;
}
.MAXScript {
  background-color: #00a6a6;
}
.Mercury {
  background-color: #ff2b2b;
}
.Metal {
  background-color: #8f14e9;
}
.Mirah {
  background-color: #c7a938;
}
.MTML {
  background-color: #b7e1f4;
}
.NCL {
  background-color: #28431f;
}
.Nemerle {
  background-color: #3d3c6e;
}
.nesC {
  background-color: #94b0c7;
}
.NetLinx {
  background-color: #0aa0ff;
}
.NetLinx-ERB {
  background-color: #747faa;
}
.NetLogo {
  background-color: #ff6375;
}
.NewLisp {
  background-color: #87aed7;
}
.Nimrod {
  background-color: #37775b;
}
.Nit {
  background-color: #009917;
}
.Nix {
  background-color: #7e7eff;
}
.Nu {
  background-color: #c9df40;
}
.Objective-C {
  background-color: #438eff;
}
.Objective-Cpp {
  background-color: #6866fb;
}
.Objective-J {
  background-color: #ff0c5a;
}
.OCaml {
  background-color: #3be133;
}
.Omgrofl {
  background-color: #cabbff;
}
.ooc {
  background-color: #b0b77e;
}
.Opal {
  background-color: #f7ede0;
}
.Oxygene {
  background-color: #cdd0e3;
}
.Oz {
  background-color: #fab738;
}
.Pan {
  background-color: #cc0000;
}
.Papyrus {
  background-color: #6600cc;
}
.Parrot {
  background-color: #f3ca0a;
}
.Pascal {
  background-color: #b0ce4e;
}
.PAWN {
  background-color: #dbb284;
}
.Perl {
  background-color: #0298c3;
}
.Perl6 {
  background-color: #0000fb;
}
.PHP {
  background-color: #4f5d95;
}
.PigLatin {
  background-color: #fcd7de;
}
.Pike {
  background-color: #005390;
}
.PLSQL {
  background-color: #dad8d8;
}
.PogoScript {
  background-color: #d80074;
}
.Processing {
  background-color: #0096d8;
}
.Prolog {
  background-color: #74283c;
}
.Propeller-Spin {
  background-color: #7fa2a7;
}
.Puppet {
  background-color: #302b6d;
}
.Pure Data {
  background-color: #91de79;
}
.PureBasic {
  background-color: #5a6986;
}
.PureScript {
  background-color: #1d222d;
}
.Python {
  background-color: #3572a5;
}
.QML {
  background-color: #44a51c;
}
.R {
  background-color: #198ce7;
}
.Racket {
  background-color: #22228f;
}
.Ragel-in-Ruby-Host {
  background-color: #9d5200;
}
.RAML {
  background-color: #77d9fb;
}
.Rebol {
  background-color: #358a5b;
}
.Red {
  background-color: #ee0000;
}
.Ren-Py {
  background-color: #ff7f7f;
}
.Rouge {
  background-color: #cc0088;
}
.Ruby {
  background-color: #701516;
}
.Rust {
  background-color: #dea584;
}
.SaltStack {
  background-color: #646464;
}
.SAS {
  background-color: #b34936;
}
.Scala {
  background-color: #dc322f;
}
.Scheme {
  background-color: #1e4aec;
}
.Self {
  background-color: #0579aa;
}
.Shell {
  background-color: #89e051;
}
.Shen {
  background-color: #120f14;
}
.Slash {
  background-color: #007eff;
}
.Slim {
  background-color: #ff8f77;
}
.Smalltalk {
  background-color: #596706;
}
.SourcePawn {
  background-color: #5c7611;
}
.SQF {
  background-color: #3f3f3f;
}
.Squirrel {
  background-color: #800000;
}
.Stan {
  background-color: #b2011d;
}
.Standard-ML {
  background-color: #dc566d;
}
.SuperCollider {
  background-color: #46390b;
}
.Swift {
  background-color: #ffac45;
}
.SystemVerilog {
  background-color: #dae1c2;
}
.Tcl {
  background-color: #e4cc98;
}
.TeX {
  background-color: #3d6117;
}
.Turing {
  background-color: #45f715;
}
.TypeScript {
  background-color: #2b7489;
}
.Unified-Parallel-C {
  background-color: #4e3617;
}
.Unity3D-Asset {
  background-color: #ab69a1;
}
.UnrealScript {
  background-color: #a54c4d;
}
.Vala {
  background-color: #fbe5cd;
}
.Verilog {
  background-color: #b2b7f8;
}
.VHDL {
  background-color: #adb2cb;
}
.VimL {
  background-color: #199f4b;
}
.Visual-Basic {
  background-color: #945db7;
}
.Volt {
  background-color: #1f1f1f;
}
.Vue {
  background-color: #2c3e50;
}
.Web-Ontology-Language {
  background-color: #9cc9dd;
}
.wisp {
  background-color: #7582d1;
}
.X10 {
  background-color: #4b6bef;
}
.xBase {
  background-color: #403a40;
}
.XC {
  background-color: #99da07;
}
.XQuery {
  background-color: #5232e7;
}
.Zephir {
  background-color: #118f9e;
}

.SCSS {
  background-color: #c6538c;
}

div.project-case {
  width: 75vw;
  margin: 20px 0px;
  position: relative;
}

div.project-case > div.project-case-header {
  padding: 10px;
}

@media (max-width: 660px) {
  div.project-case > div.project-case-header > div.middle-section {
    display: flex;
    flex-direction: column-reverse;
  }

  div.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.buttons > a {
    width: 100%;
    margin: 10px 0px;
  }
  div.buttons > a#custom-a > button {
    width: 100%;
  }

  div.project-case > div.project-case-header > p {
    font-size: 3vw;
  }
}

section.project-page-img > img {
  width: 50vw;
  height: 50vh;
}

section.project-page-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.project-page-projects {
  padding: 20px;
}

section.project-page-projects > h1 {
  font-size: 35px;
}

div#projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Comfortaa", cursive;
  background-color: black;
  color: white;
}

.shadow-text {
  text-shadow: 3px 4px 2px #ff6584;
}

