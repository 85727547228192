@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Comfortaa", cursive;
  background-color: black;
  color: white;
}

.shadow-text {
  text-shadow: 3px 4px 2px #ff6584;
}
